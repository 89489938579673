import jwt_decode from "jwt-decode";
import {
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";

import { WalletEntryPosition } from "@particle-network/auth";
import { SolanaWallet } from "@particle-network/solana-wallet";
import {
  Ethereum,
  EthereumGoerli,
  Solana,
} from "@particle-network/chains";
import { ParticleProvider } from "@particle-network/provider";
import {
  evmWallets,
  solanaWallets,
  ParticleConnect,
} from "@particle-network/connect";
import { ParticleNetwork, UserInfo } from "@particle-network/auth";
import "@particle-network/connect-react-ui/dist/index.css";

import video1 from './/media/video.mp4';
import poster1 from './/media/bloomverseteaser.webp';
import logo from './/media/bloomlogo.png';

import upgradingImg from './/media/upgrading.png';
import discord from './/media/discor-btn.png';

import {
  Keypair,
  Connection,
  Transaction,
  TransactionInstruction,
  SystemProgram,
  clusterApiUrl,
  PublicKey,
  sendAndConfirmTransaction,
  LAMPORTS_PER_SOL,
} from "@solana/web3.js";

import {
  transfer,
  getMint,
  getOrCreateAssociatedTokenAccount,
  createTransferInstruction,
} from "@solana/spl-token";

import styles from "./app.module.css";
import { Grid, Box } from '@mui/material';

  const Play = (props) => {
  const web3 = require("@solana/web3.js");

  Buffer.from("anything", "base64");
  window.Buffer = window.Buffer || require("buffer").Buffer;

  //const user = useContext(UserContext);
  const { classes } = props;
  var unityGame;
  //window.unityGame = useUnityContext;


  window.container = document.querySelector("#unity-container");
  window.canvas = document.querySelector("#unity-canvas");
  window.loadingBar = document.querySelector("#unity-loading-bar");
  window.progressBarFull = document.querySelector("#unity-progress-bar-full");
  window.fullscreenButton = document.querySelector("#unity-fullscreen-button");
  window.warningBanner = document.querySelector("#unity-warning");
  window.rpmHideButton = document.getElementById("rpm-hide-button");
  window.canvasWrapper = document.getElementById("canvas-wrap");

  let [playerModal,setPlayerModal] = useState(false);
  let [playerVideo,setPlayerVideo] = useState("-Arp51Hk8X8");

  const [publicKey, setPublicKey] = useState();
  const [providerW, setProvider] = useState("");


  let anim1Ref = React.createRef();
 
  //const [walletParticle, setWalletParticle] = useState(null);

  const walletCurrent = React.useRef(null);
  const [first, setFirst] = useState(false);
  const currentTip = useRef(0);
  const [tipInterval, setTipInterval] = useState(null);

  const [solanaKey,setSolanaKey] = useState("");

  const [maintenance, setMantainance] = useState(false);
 

  const [backgroundR, setBackground] = useState("GameLoadingScreen.jpg");

  const [setsrc, setSrc] = useState("");
  const [iframedisplay, setIframeDisplay] = useState("none");

  /*const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );*/

  const animtease = () => {
    //setAnim1(true);
     setPlayerModal(true);
     setPlayerVideo("usOEI1yMS10")
  }
  

  
function _takeScreenshot(){
  const dataUrl = takeScreenshot("image/jpg", .9);
  console.log(dataUrl)
  const linkSource = dataUrl;
  const downloadLink = document.createElement("a");
  downloadLink.href = linkSource;
  downloadLink.download = "bloomverseChest.png";
  downloadLink.click();
}

 function getProvider() {
    if ("solana" in window) {
        const provider = window.solana;
        if (provider.isPhantom) {
            return provider;
        }
    }
    return null;
}


  const tips = [
    "Remember to Enable Hardware Acceleration & Run in Background if you are using Chrome browser.",
    "Remember to disable brave Shields if you are using Brave browser.",
    "Loading time for first time player may take 1-5 minutes depending on player's internet speed and PC capabilities",
    "Bloomverse is the gamified fight for liberty, your support means a lot to the global fight for freedom! ",
    "$Bloomies can be used to mint new items and weapons, claim exclusive rights and get products from sponsors!",
    "Bloomverse is a self funded community initiative, this is only the begining!",
    "The Bloomverse Closed Beta will have countless guilds competing against each other and over a Million Dollars in Prizes ",
    "Bloomverse is the first game created to benefit your real life as you play, no more gamer remorse!",
    "Bloomverse drafting stage will allow you to pick competitive and strategic gear to have specific advantages in game, choose wisely!",
  ];

  const tipsImages = [
    "./chrome.jpg",
    "./shield.jpg",
     null,
     null,
     null,
     null,
     null,
     null,
     null,
  ];

  const [messageTip, setTip] = useState("Connecting to bloomverse...");
  const [imageTip, setImageTip] = useState(null);
  const tipTime = 8000; //ms

  const backgrounds = [
    "GameLoadingScreen.jpg",
    "GameLoadingScreen2.jpg"
  ]

  const cdnOrigin =  "https://playbloomverse.nyc3.cdn.digitaloceanspaces.com/game/"; // 
  const cdnEndPoint = "https://playbloomverse.nyc3.cdn.digitaloceanspaces.com";
  const cdnSubdomain = "https://cdn1.bloomverse.io/game/"//"https://cdn1.bloomverse.io";
  const apiURL = "https://api.bloomverse.io/"; // "http://localhost:8103/";


  const {unityProvider, unityContext, takeScreenshot,  loadingProgression, isLoaded,
    unload,
    sendMessage,
    addEventListener,
    removeEventListener,} = useUnityContext({
    loaderUrl: cdnOrigin + "Build/game.loader.js",
    dataUrl: cdnOrigin + "Build/game.data",
    frameworkUrl: cdnOrigin + "Build/game.framework.js",
    codeUrl: cdnOrigin + "Build/game.wasm",
    streamingAssetsUrl: cdnOrigin + "StreamingAssets",
    productName: "Bloomverse",
    productVersion: "1.6.5",
    companyName: "Bloomverse Studios",
    webGLContextAttributes: {
      preserveDrawingBuffer: true,
      alpha: false
    },
  });
  


  window.unityGame = unityContext;



  function changeMsg() {
    console.log("Changing mesg",currentTip);
    if (first) {
      setTip(tips[0]);
    } else {
      //setTip(tips[randomNumberInRange(0, tips.length - 1)]);
      setTip(tips[currentTip.current]);
      setImageTip(tipsImages[currentTip.current])
      currentTip.current = currentTip.current + 1;
      if(currentTip.current==tips.length){
        currentTip.current = 0;
      }
    }
  }

  function randomNumberInRange(min, max) {
    // 👇️ get number between min (inclusive) and max (inclusive)
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function handleClickMessage() {
    sendMessage("CryptoManager", "PrintMessage", "ASDF");
  }

  const handleClickGoogle = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      //console.log(tokenResponse);
      const xhr = new XMLHttpRequest();
      xhr.open(
        "GET",
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=` +
          tokenResponse.access_token
      );
      xhr.setRequestHeader(
        "Authorization",
        `Bearer ${tokenResponse.access_token}`
      );
      xhr.onload = function () {
        if (this.status >= 200 && this.status < 300)
          sendMessage(
            "CryptoManager",
            "OnGoogleLogin",
            JSON.parse(this.responseText).email
          );
      };
      xhr.send();
    },
    onError: () => {
      //console.log("Login Failed");
      sendMessage("CryptoManager", "OnGoogleLoginError", "login Error");
    },
  });

  const handleOpenURL = useCallback(
    async (url) => {
      //console.log(url, "handleOPENURLREACT");
      window.open(url, "_blank");
    },
    [sendMessage]
  );

  const handlePasteText = useCallback(
    async (pastedText) => {
      sendMessage("UIRPMload", "GetPastedText", pastedText);
    },
    [sendMessage]
  );

  function parse(event) {
    try {
      return JSON.parse(event.data);
    } catch (error) {
      return null;
    }
  }


  // Phantom
  const handleAskTransaction = useCallback(
    async (amount, tokenId, tokenName) => {
      const TOKEN = "C7SBQ7Pb8D9wbkfkvjXRCdAEukubXdYC38fp4yBTz2ue"; //'8qiGgayKGDfpt3VG6oa47qnzF4Ju3K12Ez2C6vMzsdTk' bloomie
      const WALLET_SERVER = "DiF1nzdqxdK49svrLztBxkSdAQ1PJwxs9mYiaP5okWQq";

      //console.log(amount, tokenId, tokenName, "data");

      // Construct wallet keypairs
      const fromWallet = getProvider();

      //console.log(fromWallet, "fromWallet.publicKey");
      // Connect to cluster
      const connection = new Connection(
        "https://magical-bitter-sanctuary.solana-mainnet.discover.quiknode.pro/e1b4afe48cc598fe882dc1dcac44225212e93c6a/",
        "confirmed"
      );
      //console.log("Endpoint: ", connection.rpcEndpoint);

      // let blockhash = (await connection.getLatestBlockhash("finalized")).blockhash
      //  console.log("recentBlockhash: ", blockhash);
      // console.log('Endpoint: ', connection.rpcEndpoint)

      //let blockhash = await connection.getRecentBlockhash('finalized').blockhash;

      await fromWallet.connect();
      // console.log('From Wallet: ', fromWallet.publicKey)

      // Create new token mint
      const mint = await getMint(connection, new PublicKey(TOKEN));
      // console.log('Mint: ', mint.address)

      const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        fromWallet,
        new PublicKey(TOKEN),
        fromWallet.publicKey
      );
      //console.log("Token From: ", fromTokenAccount.address);

      const toTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        fromWallet,
        new PublicKey(TOKEN),
        new PublicKey(WALLET_SERVER)
      );
      //console.log("Token To:", toTokenAccount.address);

      const instructions = [];

      instructions.push(
        createTransferInstruction(
          fromTokenAccount.address,
          toTokenAccount.address,
          fromWallet.publicKey,
          0.1 * 1000000
        )
      );

      const transaction = new Transaction().add(...instructions);
      transaction.feePayer = fromWallet.publicKey;
      transaction.recentBlockhash = (
        await connection.getRecentBlockhash()
      ).blockhash;

      /*- const transactionSignature = await connection.sendRawTransaction(
          transaction.serialize(),
          { skipPreflight: true }
        );*/
      try {
        const { signature } = await fromWallet.signAndSendTransaction(
          transaction
        );
        //console.log(signature + "sginature");
        sendMessage("CryptoManager", "OnTokenTransaction", signature);
      } catch (e) {
        //console.log(e + " error ");
        sendMessage("CryptoManager", "OnTokenTransaction", "PhantomError");
      }

      //
    },
    [sendMessage]
  );

   const handleAskTransactionSolana = useCallback(

    async (solPrice,bloomiesAmount,userWallet) => {

      console.log("transaction solana", walletCurrent.current)

      if(walletCurrent.current==null){  
        sendMessage("CryptoManager", "OnSolanaTransaction", "PhantomError");  
      }else{
      console.log(solPrice,"solprice",bloomiesAmount,userWallet);
      const WALLET_SERVER = "DiF1nzdqxdK49svrLztBxkSdAQ1PJwxs9mYiaP5okWQq"; // Deposit Wallet
 
      const fromWallet = walletCurrent.current;
      const connection = new Connection(
        "https://magical-bitter-sanctuary.solana-mainnet.discover.quiknode.pro/e1b4afe48cc598fe882dc1dcac44225212e93c6a/",
        "confirmed"
      );
  
      const pPublicKey = await fromWallet.publicKey;
  
      const transferInstruction = SystemProgram.transfer({
        fromPubkey: pPublicKey,
        toPubkey: new PublicKey(WALLET_SERVER),
        lamports: parseInt(parseFloat(solPrice) * web3.LAMPORTS_PER_SOL) // Convert SOL to lamports
      });
      
      const transaction = new Transaction().add(transferInstruction);
      transaction.feePayer = pPublicKey;
      let { blockhash } = await connection.getRecentBlockhash();
      transaction.recentBlockhash = blockhash;
  
      try {
        
        const result = await fromWallet.signAndSendTransaction(transaction);

        // SEND BLOOMIES TO USER
        var data = {
          walletId : userWallet,
          amount : bloomiesAmount,
        }
        const xhr = new XMLHttpRequest();
          xhr.open(
            "POST",
            apiURL + "crypto/send_bloomiesSold"
          );
          xhr.setRequestHeader("Content-Type", "application/json");
          xhr.onload = function () {
            if (this.status >= 200 && this.status < 300)
              console.log("transacion return", this.status)
            // rESPONSE FOR UNITY
            sendMessage("CryptoManager", "OnSolanaTransaction", result.toString());
          };
          xhr.send(JSON.stringify(data));

        //sendMessage("CryptoManager", "OnSolanaTransaction", result);
      } catch (e) {
        console.log(e, "transacion error on playholder");
        sendMessage("CryptoManager", "OnSolanaTransaction", "PhantomError");
      }
      }

    },
    [sendMessage] // Ensure sendMessage is a stable reference
  );
 
  const handleClickPhantom = useCallback(async () => {
    console.log("Connect to Phantom");

    try {
        let provider = getProvider();
      console.log(provider,"rovider");

        if (!provider) {
            throw new Error("No Phantom wallet provider found. Please install the browser extension.");
        }

        let connection = await provider.connect();
        let pubkey = provider.publicKey;

        if (!pubkey) {
            throw new Error("Failed to retrieve public key from Phantom wallet.");
        }

        console.log(pubkey.toString() + " Connected Phantom");
        walletCurrent.current = provider;
        setPublicKey(pubkey.toString());
        
        sendMessage("CryptoManager", "OnPhantom", pubkey.toString());


    } catch (e) {
        alert("No phantom wallet detected. Please install the browser extension. Error: " + e.message);
    }
}, [sendMessage]);



  const checkIfPhantom = useCallback(async () => {

    let provider = getProvider();
    let connection = await provider.connect();
    

    walletCurrent.current = provider;
    console.log("Checking if phantom ", provider.publicKey);
    if (provider.publicKey!=null) {
      console.log("sending connected phantom");
      sendMessage("CryptoManager", "IsPhantom","Connected")
    }else{
      console.log("sending not connected phantom ");
      sendMessage("CryptoManager", "IsPhantom","notConnected")
    }
  }, [sendMessage]);

 
  const checkIfParticle = useCallback(async () => {

    let provider = getProvider();
    console.log("Checking if particle", provider);
    if (provider!=null) {
      console.log("sending connected");
      sendMessage("CryptoManager", "IsParticle","Connected")
    }else{
      console.log("sending not connected");
      sendMessage("CryptoManager", "IsParticle","notConnected")
    }
  }, [sendMessage]);


  const handleClickTokenMarket = useCallback(async () => {
     //initTransak();
  }, [sendMessage]);

  // useEffect(() => {
  //     setTimeout(() => {
  //         let provider = getProvider()

  //         setProvider(provider)
  //     }, 2000);
  // })

  // useEffect(() => {
  //     addEventListener("CheckIfPhantom", checkIfPhantom)
  //     return () => {
  //         removeEventListener("CheckIfPhantom", checkIfPhantom)
  //     }
  // }, [addEventListener, removeEventListener, checkIfPhantom])

  function checkParamInURL(param) {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.has(param);
  }

  useEffect(() => {
    if ((!first & !maintenance) || checkParamInURL("nm") ) {

      var inter =  setInterval(changeMsg, tipTime);
      setTipInterval(inter);
      console.log("Setting back","url('./game/TemplateData/" + backgrounds[randomNumberInRange(0, backgrounds.length - 1)] + "')",backgrounds[randomNumberInRange(0, backgrounds.length - 1)])
      setBackground(backgrounds[randomNumberInRange(0, backgrounds.length - 1)]);
      setFirst(true);      
    }else{
       setBackground("back-bloom.jpg");
    }
  }, []);

  useEffect(() => {
    setFirst(false);
    clearInterval(tipInterval);
  }, [isLoaded]);

  useEffect(() => {
    addEventListener("ClickPhantom", handleClickPhantom);
    addEventListener("TakeScreenshot", _takeScreenshot);
    addEventListener("ClickParticle", handleClickPhantom);  //old handleClickparticle
    addEventListener("TokenMarket", handleClickTokenMarket);
    addEventListener("CheckIfPhantom", checkIfPhantom);
    addEventListener("CheckIfParticle", checkIfPhantom);
    addEventListener("bloomieTransaction", handleAskTransaction);
    addEventListener("solanaTransaction", handleAskTransactionSolana);
    addEventListener("ClickGoogle", handleClickGoogle);
    addEventListener("OpenURL", handleOpenURL);
    addEventListener("PasteText", handlePasteText);
    return () => {
      removeEventListener("TakeScreenshot", _takeScreenshot);
      removeEventListener("ClickPhantom", handleClickPhantom);
      removeEventListener("CheckIfPhantom", checkIfPhantom);
      removeEventListener("CheckIfParticle", checkIfPhantom);
      removeEventListener("TokenMarket", handleClickTokenMarket);
      removeEventListener("bloomieTransaction", handleAskTransaction);
      removeEventListener("solanaTransaction", handleAskTransactionSolana);
      removeEventListener("ClickGoogle", handleClickGoogle);
      removeEventListener("OpenURL", handleOpenURL);
      removeEventListener("PasteText", handlePasteText);
    };
  }, [
    addEventListener,
    removeEventListener,
    _takeScreenshot,
    handleClickPhantom,
    checkIfPhantom,
    checkIfParticle,
    handleClickTokenMarket,
    handleClickGoogle,
    handleOpenURL,
    handlePasteText,
    handleAskTransactionSolana,

  ]);

  return (

    

    <div
      className="App"
      style={{
        background: "url('/game/TemplateData/" + backgroundR + "') center center / cover",
        backgroundSize: 'cover',
        height: "100vh",
        
      }}
    >
      {/*    <div onClick={initTransak}>
      <a>HOLA MORROS</a>
    </div>
    */}
      {!maintenance ||  checkParamInURL("nm") ? (
        <div className={styles.container}>

       
        <div className={styles.unityWrapper}>
          {isLoaded === false && (
            
            <div>
               <div style={{position:'fixed',height:'40px',top:0,backgroundColor:'rgb(40 173 255 / 63%)',width:'100%',fontSize:'18px',fontWeight:'bold',color:'#fff',left:'0px',lineHeight:'40px'}}>
        May experience long loading times based on your PC download speed, sit back and relax! 
        </div>
              {/* <video style={{marginTop:100}} width="320" height="240" autoPlay='' loop muted>
                  <source src="./loadingvideo.mp4" type="video/mp4" />
                </video>
           */}
           
              <div
                style={{
                  position: "absolute",
                  bottom: 120,
                  textAlign: "center",
                  left: 0,
                  width: "100%",
                  paddingRight: '40px',
                  paddingLeft : '40px',
                  color: "#fff",
                  backgroundColor: "#33648199",
                  padding: "10px",
                  fontSize:'24px'
                }}
              >
                {messageTip}
              </div>
          
              <img
                src="./game/TemplateData/RefreshingIcon.png"
                style={{
                  width: "30px",
                  position: "absolute",
                  bottom: "70px",
                  marginLeft: "-15px",
                }}
                className={styles.rotate}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 10,
                  textAlign: "center",
                  left: 0,
                  width: "100%",
                  color: "#fff",
                  padding: "0px",
                  fontSize: '18px'
                }}
              >
                {Number(loadingProgression * 100).toFixed(1) + "%"} 
              </div> 
              <div className={styles.loadingBar}>
                <div
                  className={styles.loadingBarFill}
                  style={{ width: loadingProgression * 330 }}
                />
              
                <div
                />
                
              </div>

              <div style={{position:"absolute",left:'50%', bottom:'0px'}}>
              {imageTip!=null ? (
                 <div style={{position:"absolute",
                 bottom:'220px',
                 backgroundImage:`url(${imageTip})`,
                 backgroundSize:"cover",
                 width:"600px",
                 height: "240px",
                 left:'-300px'
                ,boxShadow: '#00000047 10px 5px 5px'}}>
                </div>
              ) : ""}

            </div>

            </div>
          )}



          <Unity
            style={{
              width: "100%",
              height: "100vh",
              display: isLoaded ? "block" : "none",
            }}
            unityProvider={unityProvider}
            /*devicePixelRatio={devicePixelRatio}*/
          />

{/*
<button onClick={_takeScreenshot}>Take Screenshot</button> */}

          <iframe
            allowtransparency="true"
            style={{
              position: "fixed",
              top: "100px",
              right: "41px",
              borderRadius: "30px",
              border: "none",
              display: iframedisplay,
            }}
            src={setsrc}
            width={400}
            height={600}
          ></iframe>
          {/* <button onClick={handleClickMessage}>Send message</button>
            <button onClick={handleAskTransaction}>Ask transaction</button>
    */}
        </div>
      </div>

      ) : (<Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            textAlign: 'center',
            backgroundColor: 'transparent',  // Update with your desired background color
        }}
    >


    <Grid container spacing={2} maxWidth ={'lg'}>
    <Grid item xs={12}>
        <img src={logo} style={{height:"120px"}} />
    </Grid>
    <Grid container>
       
        <Grid style={{textAlign:"center"}} item  md={12} >
              <video
              poster={poster1}
              onClick={animtease}
              ref = { anim1Ref }      
                loop
                autoPlay
                controls
                playsInline="true" disablePictureInPicture="true"
                style={{
                  backgroundColor: 'rgb(1 2 3 / 62%)',
                  cursor:'pointer',
                  position:'relative',
                  marginRight:'0%',
                  top: 0,
                  textAlign:'center',
                  maxWidth: '100%',
                  padding:20,
                  height: '290px',
                  borderRadius: '8px',  
                  zIndex : 1000,
                }}  >  <source src= { video1 } type="video/mp4" />
            </video>  
        </Grid>
        <Grid style={{color:'#fff', textAlign:"center", width:"100%"}} md={12} >
            <img style={{height:90,textAlign:'center'}} src={upgradingImg} />
            <ul style={{listStyle:"none"}}>
              <li>Network optimization for upcoming Tournaments</li>
              <li>Migrating to WebGPU</li>
              <li>Enhanced UI</li>
              <li>Fixing identified bugs and issues</li>
              <li><a href="https://discord.gg/fmDWvnZaHh" target="_blank"><img src={discord} style={{height:50,marginTop:"40px",textAlign:"center",cursor:"pointer"}} /></a></li>
              </ul>
        </Grid>
    </Grid>
    </Grid>
  
      </Box>
      )}
      
    </div>
  );
};

export default Play;
